/* Juegos.css */

.juegos-container {

  display: flex;

  flex-wrap: wrap;

  gap: 20px;

  justify-content: center;

  padding: 20px;

  background-color: var(--color-neutral-white); /* Neutral white background */

}



.game-card {

  display: flex;

  justify-content: center;

  align-items: center;

  width: 100px;

  height: 100px;

  text-decoration: none;

  color: var(--color-earth-brown); /* Dark brown text color */

  background-color: var(--color-light-brown); /* Light brown background */

  border-radius: 8px;

  transition: transform 0.2s, background-color 0.2s;

}



.game-card:hover {

  transform: scale(1.05);

  background-color: var(--color-gold); /* Gold background on hover */

}



.game-name {

  text-align: center;

}



@media (min-width: 600px) {

  .game-card {

    width: 150px;

    height: 150px;

  }

}



@media (min-width: 900px) {

  .game-card {

    width: 200px;

    height: 200px;

  }

}

