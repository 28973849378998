@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');

@font-face {
  font-family: 'Jerome';
  src: url('../assets/fonts/Jerome.otf') format('opentype');
}

:root {
  --color-soft-yellow: #F6E5B5; /* Color 1 (Amarillo claro) */
  --color-yellow: #F4B84A; /* Color 2 (Amarillo) */
  --color-light-orange: #E29227; /* Color 3 (Naranja claro) */
  --color-dark-orange: #A8591D; /* Color 4 (Naranja oscuro) */
  --color-brown: #66382B; /* Color 5 (Marrón) */
}

.mas-character-name {
  font-family: 'Jerome', sans-serif; /* Usa la fuente importada */
  color: var(--color-brown); /* Usar el color marrón para el texto */
  font-weight: bold; /* Texto en negrita */
  text-transform: uppercase; /* Transforma el texto a mayúsculas */
  font-size: 1rem; /* Ajusta el tamaño de la fuente según sea necesario */
  text-align: center;
}

.mas-personajes-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Centra los elementos horizontalmente */
  padding: 10px;
}

.mas-character-group {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.mas-character-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3px; /* Reducir el margen para acercar más los personajes */
  width: 100px; /* Reducir el ancho de los personajes */
  border: 1px solid var(--color-yellow); /* Añadir borde amarillo alrededor del personaje */
  padding: 5px; /* Añadir padding dentro del borde */
  border-radius: 8px; /* Bordes redondeados */
  background-color: var(--color-soft-yellow); /* Fondo amarillo claro */
}

.mas-character-image {
  width: 100%; /* Ajustar el tamaño de la imagen */
  height: auto;
  margin-bottom: 4px;
}

.mas-character-text-image {
  width: 30%; /* Ajustar el tamaño de la imagen de texto al 30% */
  height: auto;
}

.mas-character-detail {
  display: flex;
  align-items: center;
  margin: 20px;
}

.mas-character-detail img {
  width: 200px; /* Ajustar el tamaño de la imagen de detalle */
  height: auto;
  margin-right: 20px;
}

.mas-text-description {
  background-image: url('../assets/img/paper.png');
  background-size: contain;
  background-repeat: no-repeat;
  padding: 5%;
  box-sizing: border-box;
  width: 500px; /* Reducir el ancho */
  height: 700px; /* Reducir la altura */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.mas-text-description h1 {
  margin-bottom: 20px;
}

.mas-text-description p {
  font-size: 16px; /* Ajustar el tamaño de la fuente */
}

.buttons-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.button {
  margin: 5px;
  padding: 10px 20px;
  background-color: var(--color-dark-orange); /* Naranja oscuro */
  color: var(--color-soft-yellow); /* Amarillo claro */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.button:hover {
  background-color: var(--color-light-orange); /* Naranja claro */
}

@media (max-width: 1200px) {
  .mas-character-card {
    width: calc(16.6% - 6px); /* Ajuste para pantallas más pequeñas */
  }
}

@media (max-width: 900px) {
  .mas-character-card {
    width: calc(25% - 6px); /* Ajuste para pantallas más pequeñas */
  }
}

@media (max-width: 600px) {
  .mas-character-card {
    width: calc(33% - 6px); /* Ajuste para pantallas más pequeñas */
  }
  .mas-character-detail {
    flex-direction: column;
  }
  .mas-character-detail img {
    margin-bottom: 20px;
    margin-right: 0;
  }
  .mas-text-description {
    width: 100%;
    height: auto;
  }
}
