@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');

:root {
  --color-light-yellow: #F6E5B5; /* Amarillo claro */
  --color-yellow: #F4B84A; /* Amarillo */
  --color-light-orange: #E29227; /* Naranja claro */
  --color-dark-orange: #A8591D; /* Naranja oscuro */
  --color-brown: #66382B; /* Marrón */
  --color-light-green: #A8E6A1; /* Verde claro */
  --color-light-blue: #A9D4EF; /* Azul claro */
  --color-bright-red: #FF6F61; /* Rojo brillante */
  --color-light-purple: #D4A5E2; /* Morado claro */
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: var(--color-light-yellow);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Añadiendo sombra */
  position: relative; /* Para el menú móvil */
}

.navbar-logo {
  display: flex;
  align-items: center;
}

.navbar-logo img {
  height: 50px;
}

.nav-links {
  display: flex;
  gap: 20px;
  list-style: none;
}

.nav-item {
  padding: 10px;
  text-decoration: none;
  color: var(--color-brown);
  font-weight: bold;
  transition: color 0.3s;
}

.nav-item:hover {
  color: var(--color-yellow);
}

.menu-icon {
  display: none;
}

/* Mobile styles */
@media (max-width: 768px) {
  .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 60px; /* Ajustar según la altura de tu navbar */
    left: 0;
    background-color: var(--color-light-yellow);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra para el menú móvil */
  }

  .nav-links.open {
    display: flex;
  }

  .menu-icon {
    display: block;
    cursor: pointer;
    font-size: 2em;
    color: var(--color-brown);
  }

  .nav-item {
    margin: 10px 0;
  }
}
