.footer {
  padding: 20px;
  text-align: center;
  background-color: #625243;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.footer p {
  margin: 0;
  font-size: 0.9rem;
}

.footer a {
  color: inherit;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}
