.videos-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 videos per row */
  gap: 20px;
  padding: 20px;
}

.video {
  text-align: center;
}

.video iframe {
  width: 100%;
  height: 200px; 
}

