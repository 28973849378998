
/* src/components/Sidebar.css */
.sidebar {
    position: fixed;
    right: 0;
    top: 60px; /* Adjust if you have a fixed navbar */
    width: 250px;
    height: calc(100% - 60px);
    padding: 20px;
    background-color: #f8f9fa;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .sidebar h3 {
    margin-bottom: 20px;
    font-size: 20px;
    color: #333;
  }
  
  .sidebar-item {
    margin-bottom: 15px;
    width: 100%;
    text-align: left;
  }
  
  .sidebar-item label {
    font-weight: bold;
    margin-right: 10px;
  }
  
  .reset-button, .next-puzzle-button {
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    border: none;
    color: #fff;
    background-color: #007bff;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .reset-button:hover, .next-puzzle-button:hover {
    background-color: #0056b3;
  }