/* src/components/PuzzleGame.css */
.puzzle-game-container {
  display: flex; /* Makes the sidebar and puzzle game container sit side by side */
}

.puzzle-game {
  flex: 1; /* Allows the puzzle game to take up the remaining space next to the sidebar */
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.puzzle-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.puzzle-thumbnail-container {
  margin-bottom: 20px;
  text-align: center;
}

.puzzle-thumbnail {
  width: auto;
  height: auto;
  max-width: 150px; /* Maximum width for the thumbnail */
  max-height: 150px; /* Maximum height for the thumbnail */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.puzzle-container {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin: 20px auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  transform: scale(0.75); /* Scale down the puzzle to 75% */
  transform-origin: top left; /* Adjust the origin to ensure proper scaling */
}

.puzzle-piece {
  cursor: grab;
  border: 1px solid #ddd;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  background-size: cover;
  background-position: center;
  object-fit: cover;
}