/* Container for the image selection grid */
.image-selection-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 columns */
    grid-template-rows: repeat(4, auto); /* 4 rows */
    gap: 20px; /* Spacing between the images */
    padding: 20px;
    justify-items: center;
    align-items: center;
  }
  
  /* Style for each game card (image container) */
  .image-selection-item {
    width: 100%;
    max-width: 200px; /* Adjust this to control the image size */
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .image-selection-item:hover {
    transform: scale(1.05); /* Slightly enlarge the image on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Darker shadow on hover */
  }
  
  /* Ensure the images take up the full width of the container */
  .image-selection-item img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Initial shadow */
    transition: box-shadow 0.3s ease;
  }
  
  /* Add a hover effect to the images */
  .image-selection-item img:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4); /* Slightly deeper shadow on hover */
  }
  
  /* Responsive design: Adjusts the grid for smaller screens */
  @media (max-width: 768px) {
    .image-selection-grid {
      grid-template-columns: repeat(2, 1fr); /* Adjust to 2 columns on smaller screens */
      grid-template-rows: repeat(auto-fill, minmax(100px, 1fr));
    }
  
    .image-selection-item {
      max-width: 150px; /* Adjust the image size for smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .image-selection-grid {
      grid-template-columns: 1fr; /* Single column layout on very small screens */
    }
  
    .image-selection-item {
      max-width: 100%;
    }
  }
  