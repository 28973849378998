@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');

:root {
  --color-soft-cream: #eed4b3; /* Background color */
  --text-color-dark: #333; /* Text color for character names */
  --color-earth-brown: #5C4033;
  --color-tan: #D2B48C;
  --color-gold: #DAA520;
  --color-neutral-white: #FFFFFF;
  --color-terracotta-red: #CB4F3F;
}

.character-name {
  font-family: 'Bungee', cursive; /* Usa la fuente importada */
  color: #000000; /* Color negro */
  font-weight: bold; /* Texto en negrita */
  text-transform: uppercase; /* Transforma el texto a mayúsculas */
  font-size: 1rem; /* Adjust font size as needed */
  text-align: center;
}

.personajes-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Centra los elementos horizontalmente */
  padding: 10px;
}

.character-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px; /* Margen reducido para acercarlos más */
  width: 150px; /* Reducir el ancho de los personajes */
}

.character-image {
  width: 100%; /* Ajustar el tamaño de la imagen */
  height: auto;
  margin-bottom: 8px;
}

.character-detail {
  display: flex;
  align-items: center;
  margin: 20px;
}

.character-detail img {
  width: 200px; /* Ajustar el tamaño de la imagen de detalle */
  height: auto;
  margin-right: 20px;
}

.text-description {
  background-image: url('../assets/img/paper.png');
  background-size: contain;
  background-repeat: no-repeat;
  padding: 5%;
  box-sizing: border-box;
  width: 500px; /* Reducir el ancho */
  height: 700px; /* Reducir la altura */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.text-description h1 {
  margin-bottom: 20px;
}

.text-description p {
  font-size: 16px; /* Ajustar el tamaño de la fuente */
}

@media (max-width: 1200px) {
  .character-card {
    width: calc(25% - 10px); /* Ajuste para pantallas más pequeñas */
  }
}

@media (max-width: 900px) {
  .character-card {
    width: calc(33% - 10px); /* Ajuste para pantallas más pequeñas */
  }
}

@media (max-width: 600px) {
  .character-card {
    width: calc(50% - 10px); /* Ajuste para pantallas más pequeñas */
  }
  .character-detail {
    flex-direction: column;
  }
  .character-detail img {
    margin-bottom: 20px;
    margin-right: 0;
  }
  .text-description {
    width: 100%;
    height: auto;
  }
}
